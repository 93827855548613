// ♯ and ♭ symbols

// Builds a list of 12 notes with good enharmonic spellings according to key and mode
// (Avoids B#, Cb, E#, Fb) Set key <= 5 for Gb Maj, 6 for F# Maj, 7 for C# Maj.
// F#min (6), C#min (7), & G#min (8) but all others are b's (Db Maj, Eb min, Ab Maj, Bb min, etc.)
// key = 0 - 11, starting note pitch class (C = 0, Db = 1, etc); mode = 0 (Maj) or 1 (min) (to add!! --> or 2 (dim))
// returns a list of note names in chromatic order
function getSpellings(key = 0, mode = 0) {
    const names = [
        ['C', 'C'],
        ['G', 'G'],
        ['D', 'D'],
        ['A', 'A'],
        ['E', 'E'],
        ['B', 'B'],
        ['F♯', 'G♭'],
        ['C♯', 'D♭'],
        ['G♯', 'A♭'],
        ['D♯', 'E♭'],
        ['A♯', 'B♭'],
        ['F', 'F']
    ];

    key = (key * 7) % 12;  // circle of 5ths position 0 - 11
    const minor = 6 <= key && key <= 7 && mode === 1 ? 6 : 0;  // 6 & 7 are F#m & C#m, 8 is G#m instead of Abm
    let noteNames = Array(12).fill('');
    for (let i = 0; i < 12; i++) {
        const note = key <= 5 + minor ? // # 5 is Gb Major, 6 is F# Major
            names[i][i <= 6 + minor + key - mode ? 0 : 1] :
            names[i][i <= 6 + minor + key - mode ? 1 : 0];
        noteNames[(i * 7) % 12] = note;  // place notes in chromatic order
    }
    return noteNames;
}


function midiToName(midiNote: number, includeOctave: boolean = false, key: number = 0, mode: number = 0) {
    const noteNames = getSpellings(key, mode);
    const noteIdx = midiNote % 12;  // Get note index (+ octave if includeOctave is True)
    const octave = includeOctave ? Math.floor(midiNote / 12) - 1 : "";
    return noteNames[noteIdx] + (includeOctave ? octave.toString() : '');
}


function velocityToDb(velocity: number): number {
    const lowVelcutoff = 24;

    // Adjust velocities below the cutoff to the cutoff value
    if (velocity <= lowVelcutoff) {
        velocity = lowVelcutoff;
    }

    return 40 * logBase(10, velocity / 127) - 6;
}

function logBase(base: number, number: number): number {
    return Math.log(number) / Math.log(base);
}

const scaleDegreeNames = [
    ['R', 'R'],    // 0
    ['♭2', '♭2'],  // 1
    ['2', '2'],    // 2
    ['♭3', '♭3'],  // 3
    ['3', '3'],    // 4
    ['4', '4'],    // 5
    ['♯4', '♭5'],  // 6
    ['5', '5'],    // 7
    ['♭6', '♭6'],  // 8
    ['6', '6'],    // 9
    ['♭7', '♭7'],  // 10
    ['7', '7'],    // 11
];

function midiToScaleDegree(midiNote: number, rootNote: number, mode: number): string {
    const pitchClass = midiNote % 12;
    const interval = (pitchClass - rootNote + 12) % 12; // Calculate interval from root
    return scaleDegreeNames[interval][mode];
}

export { getSpellings, midiToName, midiToScaleDegree, velocityToDb };
