import React from 'react';
import { getSpellings, midiToName, midiToScaleDegree } from '../utils';
import * as Tone from 'tone';


type KeyProps = {
  note: number;
  keyflash: boolean;
  isBlack: boolean;
  style?: React.CSSProperties;
  isActive: boolean;
  rootNote: number;
  toggleNote: (note: number) => void;  // new prop
  toggledNotes: number[];
  keyCenter: number;
  mode: number;
  sampler: Tone.Sampler | null;
  shouldPlaySound: boolean;
};

// Helper function to calculate the pitch class
const pitchClass = (note: number, rootNote: number = 0) => {
  return (note + 12 - rootNote) % 12;
};

const Key: React.FC<KeyProps> = ({ note, keyflash, isBlack, style, isActive, rootNote, toggleNote, toggledNotes, keyCenter, mode, sampler, shouldPlaySound }) => {
  const pc = pitchClass(note, rootNote);
  const pcClass = ['p-root', 'min-second', 'maj-second', 'min-third', 'maj-third', 'p-fourth', 'tritone', 'p-fifth', 'min-sixth', 'maj-sixth', 'min-seventh', 'maj-seventh'][pc];
  const keyClassName = `key ${isBlack ? 'black' : 'white'} ${isActive ? 'active ' + pcClass : ''} ${keyflash ? 'keyflash' : ''}`;


  const handleKeyClick = () => {

    if (shouldPlaySound && sampler) {
      // const noteName = midiToName(note, true);
      const noteName = Tone.Midi(note).toNote();
      if (toggledNotes.includes(note)) {
        // If the note is already toggled on, then we need to stop the sample.
        sampler.triggerRelease(noteName); // Stop the note sound
      } else {
        // If the note isn't toggled, then we need to play the sample.
        sampler.triggerAttack(noteName); // Play the note sound
      }
    }

    // Toggle the note's presence in the toggledNotes array
    toggleNote(note);
  };


  return (
    <div title="Click a key to toggle on or off" className={keyClassName} style={style} onClick={handleKeyClick} data-actionable="true">
      <div>
        <div className={`flash-overlay ${keyflash ? 'flash' : ''}`}></div>
        {isActive ? <div className="name-display">{midiToName(note, false, keyCenter, mode)}</div> : null}
        {/* Use Below line for scale degree names */}
        {/* {isActive ? (<div className="name-display">{midiToScaleDegree(note, rootNote, mode)}</div>) : null} */}
      </div>
    </div>
  );
};

// export default React.memo(Key);
export default Key;

